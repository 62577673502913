import { Box, Button, Flex, Group, Stack, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { TFunction } from 'i18next';

import { usePrice } from '../../hooks';
import {
  IProduct,
  IPurchaseProduct,
  IPurchaseProductExtras,
  IPurchaseProductFiles,
  ISelectItem,
  ISubExtraOptionAnswer,
  IVariant,
  ValidLocales,
} from '../../typings';
import { isColor, isSize } from '../../utils';
import { ProductWishlistIcon } from '../common/ProductWishlistIcon';
import { QuantityInput } from '../common/QuantityInput';
import ColorOptions from './ColorOptions';
import DimensionsInputs from './DimensionsInputs';
import ExtraFile from './ExtraFile';
import OptionSelect from './OptionSelect';
import PaymentIntegrations from './PaymentIntegrations';
import ProductExtra from './ProductExtra';
import SizeOptions from './SizeOptions';

type Props = {
  product: IProduct;
  selectedVariant: IVariant | null;
  povs: { [index: number]: string } | null;
  extras: IPurchaseProductExtras<string> | null;
  extrasFiles: IPurchaseProductFiles<File> | null;
  isColorsDropdown: boolean;
  useShopAsCatalogue: boolean;
  isOutOfStock: boolean;
  hideQuantitySelector: boolean;
  hasSympl?: boolean;
  hasTabby?: boolean;
  quantity: number;
  locale?: ValidLocales;
  currency?: string;
  priceDecimalPoints?: number;
  symplStoreCode: string;
  pricingDetails: IPurchaseProduct['pricingDetails'] | null;
  minimumOrderTotal: number;
  isLessThanMOT: boolean;
  hasPolicies: boolean;
  useNewTheming: boolean;
  isMobile: boolean;
  isAddToCartDisabled?: boolean;
  isAddToCartLoading: boolean;
  isBuyNowLoading?: boolean;
  isUploadFileLoading?: boolean;
  useNotifyMe: boolean;
  hasWishlist: boolean;
  customLabels: Record<string, string>;
  isOnWishlist: (id: number) => boolean;
  toggleWishlist: (id: number) => void;
  onQuantityChange: (value: number) => void;
  onPricingDetailsChange: (value: IPurchaseProduct['pricingDetails'] | null) => void;
  onOptionValueChange: (optionId: number, value: string) => void;
  onExtraChange: (
    extraId: number,
    value: ISubExtraOptionAnswer<string> | ISubExtraOptionAnswer<string>[],
  ) => void;
  onExtrasFileChange: (value: { [x: number]: ISubExtraOptionAnswer<File> }) => void;
  openPoliciesModal?: () => void;
  openNotifyMeModal?: () => void;
  addToCart: ({ redirectToCheckout }: { redirectToCheckout: boolean }) => Promise<void>;
  buyItNow: () => void;
  t: TFunction;
};

const ProductPanel = ({
  product,
  selectedVariant,
  povs,
  extras,
  extrasFiles,
  isColorsDropdown,
  useShopAsCatalogue,
  isOutOfStock,
  hideQuantitySelector,
  hasSympl,
  hasTabby,
  quantity,
  locale,
  currency,
  priceDecimalPoints,
  symplStoreCode,
  pricingDetails,
  minimumOrderTotal,
  isLessThanMOT,
  hasPolicies,
  useNewTheming,
  isMobile,
  isAddToCartDisabled,
  isAddToCartLoading,
  isBuyNowLoading,
  isUploadFileLoading,
  useNotifyMe,
  hasWishlist,
  customLabels,
  isOnWishlist,
  toggleWishlist,
  onQuantityChange,
  onPricingDetailsChange,
  onOptionValueChange,
  onExtraChange,
  onExtrasFileChange,
  openPoliciesModal,
  openNotifyMeModal,
  addToCart,
  buyItNow,
  t,
}: Props) => {
  const mantineTheme = useMantineTheme();
  const { hovered: buyItNowHovered, ref: buyItNowRef } = useHover<HTMLButtonElement>();
  const { hovered: addToCartHovered, ref: addToCartRef } = useHover<HTMLButtonElement>();
  const { formatPrice } = usePrice({ locale, currency, priceDecimalPoints });

  const getVariantsUsed = (selectedValue?: string) => {
    const povList = Object.values(povs || {});
    // get values of POVS
    const povListWithoutSelectedValue = povList.filter((item) => item !== selectedValue);

    const filteredVariants = product.variants.filter((elements) =>
      povListWithoutSelectedValue.every((val) => elements.values.includes(val)),
    );

    /* get variants without currently selected value so when an option
      is selected other options that are out of stock remain dimmed */
    const variantsUsed =
      // show all variants if no POVs selected yet
      !povList || (selectedValue && !povList.includes(selectedValue))
        ? product.variants
        : filteredVariants;
    return variantsUsed;
  };

  const disableOption = (optionName: string, selectedValue?: string): boolean => {
    const variantsUsed = getVariantsUsed(selectedValue);
    const optionStockedOut = variantsUsed.every(({ values, quantity }) =>
      // Return true if non relevant variant
      {
        if (!values?.some((value) => value === optionName)) return true;
        // Check if quantity is zero if relevant variant
        return quantity <= 0;
      },
    );
    return optionStockedOut && product.isTracked;
  };

  const formatValues = (values: string[], selectedValue?: string): ISelectItem[] => {
    const output = values.map((value) => ({
      value,
      label: value,
      isDisabled: disableOption(value, selectedValue),
    }));
    return output;
  };

  return (
    <Stack>
      {product.productOptions?.map((option) =>
        isColor(option.option?.name) ? (
          <ColorOptions
            key={option.id}
            isMobile={isMobile}
            isColorsDropdown={isColorsDropdown}
            t={t}
            colors={formatValues(option.values, povs?.[option.id])}
            value={povs?.[option.id]}
            title={option.name}
            setValue={(color: string) => onOptionValueChange(option.id, color)}
          />
        ) : isSize(option.name) ? (
          <SizeOptions
            key={option.id}
            isMobile={isMobile}
            t={t}
            sizes={formatValues(option.values, povs?.[option.id])}
            value={povs?.[option.id]}
            setValue={(size: string) => onOptionValueChange(option.id, size)}
          />
        ) : (
          <OptionSelect
            key={option.id}
            isMobile={isMobile}
            t={t}
            option={option.name}
            values={formatValues(option.values, povs?.[option.id])}
            value={povs?.[option.id]}
            setValue={(value) => value && onOptionValueChange(option.id, value)}
          />
        ),
      )}

      {!useShopAsCatalogue && (
        <>
          {product.pricedBy !== 'unit' && (
            <DimensionsInputs
              t={t}
              pricedBy={product.pricedBy}
              pricingUnit={product.pricingUnit}
              pricingDetails={pricingDetails}
              setPricingDetails={onPricingDetailsChange}
            />
          )}
          {product.prodExtras?.map((extra) =>
            extra.type === 'file' ? (
              <ExtraFile
                key={extra.id}
                t={t}
                locale={locale}
                currency={currency}
                priceDecimalPoints={priceDecimalPoints}
                isLoading={isUploadFileLoading}
                extra={extra}
                value={extrasFiles?.[extra.id]}
                setValue={(selectedExtra) => {
                  const updatedExtrasFiles = {
                    ...extrasFiles,
                    [extra.id]: selectedExtra,
                  };

                  if (!selectedExtra.value) {
                    delete updatedExtrasFiles[extra.id];
                  }

                  onExtrasFileChange(updatedExtrasFiles);
                }}
              />
            ) : (
              <ProductExtra
                key={extra.id}
                isMobile={isMobile}
                t={t}
                locale={locale}
                currency={currency}
                extra={extra}
                value={extras?.[extra.id]}
                setValue={(selectedExtra) => onExtraChange(extra.id, selectedExtra)}
              />
            ),
          )}
          {(hasSympl || hasTabby) && (
            <PaymentIntegrations
              hasSympl={hasSympl}
              hasTabby={hasTabby}
              variant={selectedVariant}
              product={product}
              quantity={quantity}
              locale={locale}
              currency={currency}
              symplStoreCode={symplStoreCode}
            />
          )}
          {!hideQuantitySelector && (
            <QuantityInput
              isMobile={isMobile}
              isTracked={product.isTracked}
              inventory={selectedVariant?.quantity || product.quantity}
              quantity={quantity}
              min={selectedVariant?.minQuantity || product.minQuantity}
              max={selectedVariant?.maxQuantity || product.maxQuantity}
              displayTitle
              t={t}
              setQuantity={onQuantityChange}
            />
          )}

          <Box
            mt={40}
            py={isMobile ? 16 : 0}
            sx={{
              right: 0,
              bottom: 0,
              width: '100%',
              position: isMobile ? 'sticky' : 'static',
              backgroundColor: isMobile ? '#ffffff' : 'transparent',
            }}
          >
            {(!isOutOfStock || !useNotifyMe) && (
              <Flex gap="md" wrap={isMobile ? 'wrap' : 'nowrap'} w="100%">
                {!useShopAsCatalogue && hasWishlist && (
                  <ProductWishlistIcon
                    id={product.id}
                    isOnWishlist={isOnWishlist}
                    toggleWishlist={toggleWishlist}
                  />
                )}
                <Tooltip
                  label={isOutOfStock ? t('sold-out') : ''}
                  opened={addToCartHovered && isOutOfStock}
                >
                  <Button
                    ref={addToCartRef}
                    size="md"
                    variant="outline"
                    sx={{
                      flex: 1,
                      '&[data-disabled]': { pointerEvents: 'all' },
                      ...(!isOutOfStock &&
                        !isAddToCartDisabled && {
                          color: `${mantineTheme.colors['btn-secondary-label'][0]} !important`,
                          backgroundColor: `${mantineTheme.colors['btn-secondary'][0]} !important`,
                          borderColor: `${mantineTheme.colors['btn-secondary-border'][0]} !important`,
                        }),
                    }}
                    fullWidth={isMobile}
                    loading={isAddToCartLoading}
                    {...((isOutOfStock || isAddToCartDisabled) && { 'data-disabled': true })}
                    onClick={(event: any) =>
                      isOutOfStock || isAddToCartDisabled
                        ? event.preventDefault()
                        : addToCart({ redirectToCheckout: false })
                    }
                    styles={{
                      label: { overflow: 'visible' },
                    }}
                  >
                    {customLabels.addToCart || t('add-to-cart')}
                  </Button>
                </Tooltip>
                <Tooltip
                  label={
                    isOutOfStock
                      ? t('sold-out')
                      : isLessThanMOT
                      ? t('less-than-mot', {
                          amount: formatPrice(minimumOrderTotal),
                        })
                      : ''
                  }
                  opened={buyItNowHovered && (isOutOfStock || isLessThanMOT)}
                >
                  <Button
                    ref={buyItNowRef}
                    size="md"
                    variant="filled"
                    sx={{
                      flex: 1,
                      '&[data-disabled]': { pointerEvents: 'all' },
                      ...(!isOutOfStock &&
                        !isAddToCartDisabled &&
                        !isLessThanMOT && {
                          color: `${mantineTheme.colors['btn-primary-label'][0]} !important`,
                          backgroundColor: `${mantineTheme.colors['btn-primary'][0]} !important`,
                          borderColor: `${mantineTheme.colors['btn-primary-border'][0]} !important`,
                        }),
                    }}
                    fullWidth={isMobile}
                    loading={isBuyNowLoading}
                    {...((isOutOfStock || isAddToCartDisabled || isLessThanMOT) && {
                      'data-disabled': true,
                    })}
                    onClick={(event: any) =>
                      isOutOfStock || isAddToCartDisabled || isLessThanMOT
                        ? event.preventDefault()
                        : buyItNow()
                    }
                    styles={{
                      label: { overflow: 'visible' },
                    }}
                  >
                    {customLabels.checkout || t('buy-now-btn')}
                  </Button>
                </Tooltip>
              </Flex>
            )}
            {isOutOfStock && useNotifyMe && (
              <Flex gap="md" w="100%">
                {!useShopAsCatalogue && hasWishlist && (
                  <ProductWishlistIcon
                    id={product.id}
                    isOnWishlist={isOnWishlist}
                    toggleWishlist={toggleWishlist}
                  />
                )}
                <Button
                  p={0}
                  size="md"
                  variant="filled"
                  sx={{
                    flex: 1,
                    color: `${mantineTheme.colors['btn-secondary-label'][0]} !important`,
                    backgroundColor: `${mantineTheme.colors['btn-secondary'][0]} !important`,
                    borderColor: `${mantineTheme.colors['btn-secondary-border'][0]} !important`,
                  }}
                  onClick={openNotifyMeModal}
                  styles={{
                    label: { overflow: 'visible' },
                  }}
                >
                  {customLabels.notifyMe || t('notify-me')}
                </Button>
              </Flex>
            )}
            {hasPolicies && openPoliciesModal && (
              <Group position="center" mt={10}>
                <Button
                  size="sm"
                  fullWidth={isMobile}
                  variant="outline"
                  styles={(theme) => ({
                    root: {
                      color: useNewTheming
                        ? theme.colors['headings-and-links'][0]
                        : ['#fff', '#ffffff'].includes(theme.colors['btn-primary'][0])
                        ? theme.colors['btn-secondary'][0]
                        : theme.colors['btn-primary'][0],
                      fontSize: isMobile ? 14 : 16,
                      fontWeight: 400,
                      border: 'none',
                      textDecoration: 'underline',
                      backgroundColor: 'transparent',

                      '&:hover': {
                        textDecoration: 'underline',
                        backgroundColor: 'transparent',
                      },
                    },
                  })}
                  onClick={openPoliciesModal}
                >
                  {t('policies-btn')}
                </Button>
              </Group>
            )}
          </Box>
        </>
      )}
    </Stack>
  );
};

export default ProductPanel;
